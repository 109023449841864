/* You can add global styles to this file, and also import other style files */
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-table__wrapper {
  width: 100%;
  height: 100%;
  overflow-x: auto;

  mat-cell,
  mat-header-cell {
    min-height: 100%;
  }
}

.mat-table__wrapper .mat-table {
  min-width: 1000px;
  width: 100%;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
}

.mat-table__wrapper .mat-table .email-cell {
  word-wrap: normal;
  width: 180px;
  flex: auto;
}

.m-mat-dialog-container__wrapper .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;

  .m-portlet {
    margin-bottom: 0;
  }

  .alert {
    border-radius: 0 !important;
    width: 1032px;
  }
}

.mat-table__wrapper--sub .mat-table {
  min-width: 400px;

  .mat-cell:last-child {
    flex: 0 0 100px;
  }

  .mat-header-cell:last-child {
    flex: 0 0 100px;
  }
}

.ml-auto {
  margin-left: auto;
}

.example-full-width {
  width: 100%;
}

mat-radio-button {
  padding-right: 16px;
}

.m-form--alert {
  margin-top: 30px;
}

.mat-column-checkbox {
  flex: 0 0 68px;
}

.mat-column-id {
  flex: 0 0 68px;
}

.mat-column-main {
  flex: 0 0 568px;
}

.mat-column-actions {
  flex: 0 0 160px;
}

.mat-table__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-vinCode {
  flex: 0 0 170px;
}

.m-portlet-mx-0 {
  margin: -1.1rem -2.2rem 0;
  overflow-x: hidden;
}
.mat-table__wrapper .mat-table .mat-row-editing.mat-row-inactive {
  height: 0;
  min-height: 0;
  padding: 0 !important;
  border-bottom: 0 !important;
}

mat-header-cell .mat-sort-header-button {
  font-weight: bold;
}

.mat-table__wrapper {
  border-top: 1px solid #ececec;
}

.btn-add-icon mat-icon {
  line-height: 1.3;
}

m-header .m-topbar {
  float: left;
}

.mat-progress-bar {
  border-radius: 50px;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: #beee00;
}

.mat-progress-bar .mat-progress-bar-buffer {
  background-color: #95a1ba;
  opacity: 1;
}

.dropdown {
  background-color: #fff;
  box-shadow: 0 0 5px #ececec;
  position: absolute;
  width: 100px;
  padding: 5px;
  overflow: hidden;
}

.dropdown a {
  display: block;
  padding: 5px;
  color: #63656f;
  cursor: pointer;
}
.dropdown a:hover {
  text-decoration: underline;
}

.mat-select-panel {
  background: #fff;
}

.mat-select-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

body .migdal-wrap .md-drppicker .btn {
  background-color: #fff;
  border: 2px solid #ccff00;
  color: #2f3036;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: initial;
  border-radius: 10px;
  font-weight: bold;
  transition: background-color 0.4s ease 0s, border 0.4s ease 0s;
  width: 100%;
  max-width: 150px;
}

body .md-drppicker .btn:focus {
  background-color: #ccff00;
}

body .md-drppicker .btn:hover {
  cursor: pointer;
  background-color: #8AAA00;
  border: 2px solid #8AAA00;
}

body .md-drppicker .btn:active {
  cursor: pointer;
  background-color: #ccff00;
  border: 2px solid #ccff00;
}

body .md-drppicker td {
  color: #111;
  font-weight: 400;
}

body .md-drppicker td.active {
  background-color: #ccff00;
  color: #2f3036;
}

body .md-drppicker td.active:hover {
  background-color: #8AAA00;
}

body .mat-radio-button label{
  margin-bottom: 0;
} 

body .migdal-wrap .mat-radio-button label{
  display: flex;
  gap: 5px;
} 

body .migdal-wrap .mat-checkbox-layout {
  display: flex;
  gap: 5px;
}

body .migdal-wrap .mat-checkbox-inner-container {
  margin-right: 0;
}

body .migdal-wrap .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #93bb55;
}

body .migdal-wrap .mat-checkbox-layout .mat-checkbox-label .text-value {
  color: #333;
  font-size: 12px;
}

body .migdal-wrap .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e1e1e1;
}

body:has(.migdal-wrap) {
  background-color: #F8F8F8;
}

body .migdal-wrap .mat-checkbox-checked .mat-checkbox-checkmark {
  transform: scale(0.6);
}

body .migdal-wrap .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, 
body .migdal-wrap .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #B7F763;
  border-radius: 50%;
}

body .migdal-wrap .mat-checkbox-checkmark-path {
  stroke: #202020 !important;
}

body .migdal-wrap .mat-checkbox-frame {
  border-radius: 50%;
}

body .migdal-wrap .mat-checkbox-layout .mat-checkbox-label {
  font-size: 12px;
  font-weight: 400;
}

body .migdal-wrap .mat-checkbox-disabled .mat-checkbox-background {
  opacity: 0.3;
}

body .migdal-wrap .mat-calendar-body-selected {
  background-color: #93bb55;
}

body .migdal-wrap .mat-form-field {
  position: absolute;
}

body .migdal-wrap .mat-form-field-appearance-legacy .mat-form-field-infix,
body .migdal-wrap .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}

body .migdal-wrap .mat-form-field-infix {
  border-top: none;
}

body .migdal-wrap .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

body .migdal-wrap .md-drppicker .calendar-table {
  direction: ltr;
}